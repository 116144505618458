import axios from "axios";

class subscriptionApi {
    async get(id) {

        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .get(`/subscriptions/payment/id/${id}`)
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async add({ id, money, date }) {

        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .post("/subscriptions/payment", {
                id,
                money,
                date,
            })
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async remove(id, payment_id) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .delete(`/subscriptions/payment/id/${id}/payment_id/${payment_id}`)
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }
}

export default new subscriptionApi();