<template>
    <v-container fluid class="down-top-padding">
        <v-card class="white pa-3">
            <h1 class="text-center subtitle-4 black--text">تسديدات</h1>
            <h3 class="text-center subtitle-4 black--text">{{ $route.query.customer_name }}</h3>
            <h5 class="text-center subtitle-4 black--text mt-1">
                العدد الكلي {{ table.data.length }}
            </h5>
            <v-row class="mt-5">
                <v-col md="4" cols="12">
                    <div class="d-md-flex">
                        <v-btn tile color="info" class="ml-2 mb-4 mb-md-0 mb-sm-0" @click="dialogAdd.open = true">
                            اضافة <v-icon right> fa-plus </v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col md="4" cols="12">
                    <div class="d-flex flex-row">
                        <v-text-field label="البحث" outlined dense @keyup.enter="search($event)"></v-text-field>
                        <button class="search-btn" @click="search($event)">
                            <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
                        </button>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-data-table :headers="table.headers" loading-text="جاري التحميل ... الرجاء الانتظار"
                        :items="table.data" :search="table.search" :loading="table.loading" class="elevation-1"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-plus',
                            nextIcon: 'mdi-minus',
                            itemsPerPageOptions: [10, 50, 100]
                        }">
                        <template slot="item._id" slot-scope="props">
                            {{
                                props.index +
                                1
                            }}
                        </template>
                        <template v-slot:item.money="{ item }">
                            {{ numberWithComma(item.money) }}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip bottom v-if="$store.state.results.type === 'admin'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)">
                                        fa-trash
                                    </v-icon>
                                </template>
                                <span>حذف</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>

        <!-- add dialog -->
        <v-dialog v-model="dialogAdd.open" max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="headline">اضافة</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form v-model="dialogAdd.isFormValid">
                            <v-row>
                                <!-- name -->
                                <v-col cols="12">
                                    <v-text-field v-model="addData.money" :rules="Rules.required" dense label="المبلغ"
                                        outlined @keypress="isNumber" autofocus />
                                </v-col>
                                <!-- date -->
                                <v-col cols="12">
                                    <v-menu v-model="start_date" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="addData.date" :rules="Rules.required"
                                                label="تاريخ التسديد" readonly v-bind="attrs" v-on="on" dense outlined
                                                clearable></v-text-field>
                                        </template>
                                        <v-date-picker v-model="addData.date" @input="start_date = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions class="ml-6">
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogAdd.open = false">
                        الغاء
                    </v-btn>
                    <v-btn color="secondary white--text" :loading="dialogAdd.loading" :disabled="!dialogAdd.isFormValid"
                        @click="submitAdd">
                        اضافة
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- add discount dialog -->
        <!-- delete dialog -->
        <v-dialog v-model="dialogDelete.open" max-width="500px">
            <v-card style="border-radius: 0">
                <v-card-title class="headline justify-center">
                    هل انت متأكد من حذف هذا الحساب ؟
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete.open = false">
                        الغاء
                    </v-btn>
                    <v-btn color="primary white--text" :loading="dialogDelete.loading" @click="deleteItemConfirm">
                        حذف
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- End delete dialog -->
        <!--- respondMessageVue -->
        <respondMessageVue :dialogData="dialogData"></respondMessageVue>
    </v-container>
</template>
  
<script>
// ES modules
import subscriptionPaymentApi from '@/api/subscriptions_payment'
import respondMessageVue from '@/components/respondMessage.vue'
import numberWithComma from '@/constants/number'
import { getCurrentDateInString } from '@/constants/date'

export default {
    name: 'Payment',

    components: {
        respondMessageVue,
    },

    data: () => ({
        content_url: null,

        start_date: false,

        rate: 0,

        errors: {},

        is_active_select_items: [
            { text: 'مفعل', value: true },
            { text: 'غير مفعل', value: false }
        ],

        addData: {
            money: null,
            date: getCurrentDateInString(),
        },

        Rules: {
            required: [value => !!value || 'الحقل مطلوب']
        },

        searching: null,


        table: {
            search: null,
            totalData: 0,
            data: [],
            loading: true,

            headers: [
                {
                    text: '#',
                    align: 'start',
                    sortable: false,
                    value: '_id'
                },
                {
                    text: 'المبلغ',
                    sortable: false,
                    value: 'money'
                },
                {
                    text: 'التاريخ',
                    sortable: false,
                    value: 'date'
                },
                {
                    text: 'الاضافة بواسطة',
                    sortable: false,
                    value: 'added_by'
                },
                { text: 'العمليات', value: 'actions', sortable: false }
            ]
        },

        dialogData: {
            open: false,
            color: 'info',
            bodyText: 'test'
        },

        dialogDelete: {
            open: false,
            loading: false
        },

        dialogAdd: {
            open: false,
            loading: false,
            isFormValid: false
        }
    }),

    created() {
        this.getDataAxios()
    },
    methods: {
        async getDataAxios() {
            this.table.loading = true

            const response = await subscriptionPaymentApi.get(this.$route.query.id)

            if (response.status === 401) {
                this.$store.dispatch('submitLogout')
            } else if (response.status === 500) {
                this.table.loading = false
                this.showDialogFunction(response.data.results, '#FF5252')
            } else {
                this.table.loading = false
                this.table.data = response.data.results
            }
        },

        deleteItem(item) {
            this.deletedItem = { ...item }
            this.dialogDelete.open = true
        },

        async deleteItemConfirm() {
            this.dialogDelete.loading = true

            const response = await subscriptionPaymentApi.remove(this.$route.query.id, this.deletedItem._id)

            if (response.status === 401) {
                this.$store.dispatch('submitLogout')
            } else if (response.status === 500) {
                this.dialogDelete.loading = false
                this.dialogDelete.open = false
                this.showDialogFunction(response.data.message, '#FF5252')
            } else {
                this.dialogDelete.loading = false
                this.dialogDelete.open = false
                this.getDataAxios()
                this.showDialogFunction(response.data.message, 'info')
            }
        },

        async submitAdd() {

            this.dialogAdd.loading = true

            const response = await subscriptionPaymentApi.add({
                id: this.$route.query.id,
                money: this.addData.money,
                date: this.addData.date,
            })

            if (response.status === 401) {
                this.$store.dispatch('submitLogout')
            } else if (response.status === 500) {
                this.dialogAdd.open = false
                this.dialogAdd.loading = false
                this.showDialogFunction(response.data.message, '#FF5252')
            } else {
                this.dialogAdd.loading = false
                this.dialogAdd.open = false

                this.addData.money = null
                this.addData.date = null

                this.showDialogFunction(response.data.message, 'info')
                this.getDataAxios()
            }
        },

        search(evt) {
            this.table.search = evt.target.value
        },

        showDialogFunction(bodyText, color) {
            this.dialogData.open = true
            this.dialogData.bodyText = bodyText
            this.dialogData.color = color
        },

        isNumber(evt) {
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
            const keyPressed = evt.key
            if (!keysAllowed.includes(keyPressed)) {
                evt.preventDefault()
            }
        },

        getCurrentDateInString,

        numberWithComma,
    }
}
</script>
